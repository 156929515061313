<template>
  <div class="grid-x full-height">
    <div class="cell medium-6 left">
      <img class="abrnd-logo" src="/assets/images/logo/abrnd-logo-with-wordmark--white.svg" />
      <div class="intro-text">
        <div class="padding-5vw">
          <h1>{{ $t("signup.left_title") }}</h1>
          <div class="spacer--20"></div>
          <p>{{ $t("signup.left_description") }}</p>
        </div>
      </div>
      <div class="testimonial">
        <div class="padding-5vw">
          <div class="quote">{{ testimonials[currentTestimonial].quote }}</div>
          <div class="author">- {{ testimonials[currentTestimonial].author }}</div>
        </div>
      </div>
    </div>
    <div class="cell medium-6 right">
      <div class="signup-block">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpLayout',
  data () {
    return {
      currentTestimonial: 0,
      testimonials: [{
        author: 'Peter, Racefietsroutes',
        quote: '"Ik bespaar mezelf een hoop frustratie en zoekwerk naar dingen zoals kleurcodes. Op kantoor of thuis."'
      }]
    }
  }
}
</script>

<style scoped lang="scss">
.full-height {
  height: 100vh;
  overflow: scroll;
  .left {
    background: $black;
    background-image: url("https://storage.googleapis.com/abrnd-static-files/Signup%20and%20login/signup-form-bg-dot-grid.svg");
    background-size: 250%;
    padding: 5vw;
    position: relative;
    overflow: auto;

    .abrnd-logo {
      width: calc(65px);
      position: fixed;
      left: calc(50px);
      top: calc(50px);
    }

    .intro-text {
      position: absolute;
      top: 40%;
      transform: translateY(-50%);

      h1 {
        color: $white;
        font-size: 50px;
        font-weight: 300;
      }

      p {
        color: $white;
      }

    }

    .testimonial {
      position: absolute;
      bottom: 10%;
      transform: translateY(-50%);
      @media only screen and (max-height: 600px) {
        visibility: hidden;
      }
      .quote {
        font-size: 21px;
        color: $white;
        font-weight: bold;
      }
      .author {
        color: #848484;
        font-size: 20px;
      }
    }
  }

  .right {
    padding: 5vw;
    position: relative;

    .signup-block {
      top: 20%;
      transform: translateY (-50%);
      margin-top:10vh;
    }
  }
  .padding-5vw {
    padding: 0 5vw 0 0;
  }
}

</style>
