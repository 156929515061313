<template>
  <div>
    <ab-input-field
        :label="$t('forms.first_name')"
        v-model="user.name"
        type="text"
        :error="validator.name.$error"
        :errorMessage="$t('forms.required')"
    ></ab-input-field>
    <ab-input-field
        :label="$t('forms.surname')"
        v-model="user.surname"
        type="text"
        :error="validator.surname.$error"
        :errorMessage="$t('forms.required')"
    ></ab-input-field>
    <ab-input-field
        :label="$t('forms.email')"
        v-model="user.email"
        type="email"
        :error="validator.email.$error"
        :errorMessage="$t('forms.valid_email')"
    ></ab-input-field>
  </div>
</template>

<script>
export default {
  name: 'MinimumProfileData',
  props: {
    value: [Array, Object],
    validator: [Array, Object]
  },
  computed: {
    user: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
