<template>
  <signup-layout>
    <form class="signup-form" v-if="!brandCreated">
      <card-item :margin="true">
        <minimum-profile-data v-model="user" :validator="$v.user"/>
        <div class="spacer--30"></div>
        <ab-input-field
          v-model="company_name"
          :label="$t('company.company_name')"
          type="text"
          :error="$v.company_name.$error"
          :errorMessage="$t('forms.required')"
        ></ab-input-field>
        <ab-input-field
          v-model="brand_name"
          :label="$t('brand.name')"
          type="text"
          :error="$v.brand_name.$error"
          :errorMessage="$t('forms.required')"
        ></ab-input-field>
        <ab-input-field
          v-model="newBrandUrl"
          :label="$t('brand.url')"
          type="text"
          :readonly="true"
          :error="$v.brand_name.$error"
          :errorMessage="$t('forms.required')"
        ></ab-input-field>
        <ab-checkbox
          v-model="newsletter_signup"
          :label="$t('forms.newsletter_signup')"
        ></ab-checkbox>
        <ab-checkbox
          v-model="terms_agreement"
          :label="$t('forms.terms_agreement')"
          :required="true"
          :error="$v.terms_agreement.$error"
          :errorMessage="$t('forms.required')"
        ></ab-checkbox>
        <div class="spacer--20"></div>
        <vue-recaptcha @verify="onCaptchaVerify" :sitekey="recaptcha_site_key" :loadRecaptchaScript="true"></vue-recaptcha>
        <div class="login__error" v-if="submitStatus">
          <p>{{ submitStatus }}</p>
        </div>
      </card-item>
      <div class="spacer--20"></div>
      <div class="grid-x align-middle">
        <div class="cell auto text-right">
          <span
            v-if="isSaving"
            class="ab-button--white is-saving"
          ><ab-loader/> {{ $t('app.signup') }}
          </span>
          <button v-else type="submit" @click="handleSubmit" class="button--primary">
            {{ $t('app.signup') }}
          </button>
        </div>
      </div>
    </form>
    <div class="signup-form" v-else>
      <h1>Hi {{ name }}, welkom bij Abrnd!</h1>
      <h3>We hebben de Abrnd-installatie voor {{ brand_name }} klaargezet.</h3>
      Je ontvangt van ons een e-mail met instructies om je account te activeren en een wachtwoord in te stellen om je account te beveiligen.<br/>
      <br/>
      Na het kiezen van een wachtwoord kan je inloggen op Abrnd.
      <br/>
    </div>
  </signup-layout>
</template>

<script>
import SignUpLayout from '@/views/layouts/SignUpLayout'
import VueRecaptcha from 'vue-recaptcha'
import MinimumProfileData from '@/views/profile/components/MinimumProfileData'
import backend from '@/backend'
import { email, minLength, required } from 'vuelidate/lib/validators'

export default {
  name: 'SignUp',
  data () {
    return {
      user: {
        name: '',
        surname: '',
        email: ''
      },
      company_name: '',
      brand_name: '',
      newsletter_signup: false,
      terms_agreement: false,
      isSaving: false,
      brandCreated: false,
      recaptcha_response: '',
      recaptcha_site_key: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    }
  },
  methods: {
    onCaptchaVerify (response) {
      this.recaptcha_response = response
    },
    handleSubmit (e) {
      e.preventDefault()
      this.isSaving = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        setTimeout(() => {
          backend
            .post(`${process.env.VUE_APP_URL}/brand`, {
              name: this.user.name,
              surname: this.user.surname,
              email: this.user.email,
              company_name: this.company_name,
              brand_name: this.brand_name,
              newsletter_signup: this.newsletter_signup,
              terms_agreement: this.terms_agreement,
              'g-recaptcha-response': this.recaptcha_response
            })
            .then(() => {
              this.brandCreated = true
              this.isSaving = false
            })
            .catch(error => {
              this.isSaving = false
              const errorMessage = JSON.parse(error.response.data.message)
              const errorResult = []
              for (const key in errorMessage) {
                errorResult.push(errorMessage[key])
              }

              this.$modal.show('dialog', {
                title: '',
                text: errorResult.join('<br/>'),
                buttons: [
                  {
                    title: this.$t('app.close'),
                    default: true,
                    handler: () => {
                      this.$modal.hide('dialog')
                    }
                  }
                ]
              })
            })
        }, 500)
      } else {
        this.isSaving = false
      }
    }
  },
  computed: {
    newBrandUrl () {
      const slug = this.brand_name
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^a-zA-Z-0-9]+/g, '')
      if (slug.length > 0) {
        return 'https://' + slug + '.abrnd.com'
      }
      return ''
    }
  },
  components: {
    'signup-layout': SignUpLayout,
    'vue-recaptcha': VueRecaptcha,
    'minimum-profile-data': MinimumProfileData
  },
  validations: {
    user: {
      name: {
        required
      },
      surname: {
        required
      },
      email: {
        required,
        email
      }
    },
    company_name: {
      required,
      minLength: minLength(2)
    },
    brand_name: {
      required,
      minLength: minLength(2)
    },
    terms_agreement: {
      required,
      sameAs: val => val === true
    }
  }
}
</script>

<style scoped lang="scss">
.signup-form {
  h1 {
    font-size: 40px;
    font-weight: bold;
    color: #191919;
    margin-bottom: 10px;
  }

  h3 {
    color: #9D9D9D;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 50px;
  }

  .button--primary {
    border: 1px solid #191919;
    background-color: #191919;
    color: $white;
  }
}

</style>
